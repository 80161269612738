import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { firestore, functions, storage } from "../../../providers/firebase";
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Lupa from "../../../icons/lupa.svg"
import { excelToJson } from "../../../providers/excel-to-json";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { toast } from "react-toastify";
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Descargar from "../../../icons/descargar.svg"
import Delete from "../../../icons/delete.svg"
import { Grid, Select, FormControl, TextField, Collapse } from "@material-ui/core";
import { subirDocumentacionComunidad, abrirArchivo, subirDocumentacionCentroTrabajo, abrirPdf, abrirPdfCt } from "../../../providers/documentos";
import Editar from '../../../icons/editar.svg'
import ModalEditar from './editarDatosAAFF'
import Change from "../../../icons/change.svg"
import Add from "../../../icons/addBlack.svg"
import Swal from 'sweetalert2'
import ExportarListaFincasAAFF from './ExportarListaFincasAAFF'
import Tick from "../../../icons/tick.svg"
import Excel from "../../../icons/excel.svg"
import NoVisitado from "../../../components/noVisitadoComunidad";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#FF5252",
    '&$checked': {
      color: "#7bc977",
    },
    '&$checked + $track': {
      backgroundColor: "#7bc977",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#FF5252"
  },
})(Switch);

const CustomPendienteSwitch = withStyles({
  switchBase: {
    color: "#FFD700"
  },
  checked: {},
  track: {
    backgroundColor: "#FFD700"
  },
})(Switch);

class Comunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      ano: new Date().getFullYear(),
      fincas: [],
      aaff: {},
      tecnico: {},
      comercial: {},
      consultor: {},
      aaffs: [],
      nuevaLista: [],
      fincasSeleccionadas: [],
      aaffBuscado: null,
      aaffSeleccionado: null,
      aaffRazonSocialSeleccionado: null,
      show:false,
      dialog: {
        opened: false,
        title: "",
        docName: "",
        finca: undefined
      },
      dialogCentroTrabajo: {
        opened: false,
        title: "",
        docName: "",
        finca: undefined,
        ct: undefined
      },
      eliminarFinca: {
        opened: false,
        finca: undefined
      },
      eliminarCentroTrabajo: {
        opened: false,
        ct: undefined,
        finca:undefined
      },
      eliminarAAFF: {
        opened: false,
        finca: undefined,
        aaff: undefined,
        duracion: 0
      },
      cambiarAAFF:{
        opened:false,
        finca:undefined
      },
      verDocumentacion: {
        opened: false,
        nombreDoc: undefined,
        finca: undefined
      },
      verDocumentacionCentroTrabajo: {
        opened: false,
        nombreDoc: undefined,
        finca: undefined
      },
      modalEditarOpened: false,
      expandir: true,
      observaciones:'',
      hojasVisita: [],
      centrosTrabajo: []
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    let tecnico = {}
    let consultor = {}
    let comercial = {}
    let promises = []
    let hojasVisita = []
    let centrosTrabajo = []
    promises.push(firestore.collection("hojasVisita").get().then( snapshot => {
      snapshot.docs.forEach( hv => {
        hojasVisita.push(hv.data().comunidad.nif)
        if(hv.data().centrosTrabajo !== undefined){
          hv.data().centrosTrabajo.forEach(ct => {
            centrosTrabajo.push(ct.nif)
          })
        }
      })
    }))
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => {
      this.setState({ aaff: doc.data(), observaciones: doc.data().observacionesTecnico })
      if(doc.data().nifTecnico !=="" && doc.data().nifTecnico !== undefined){
        promises.push(firestore.collection('tecnicos').doc(doc.data().nifTecnico).get().then(doc => {
          tecnico = doc.data()
        }))
      }else{tecnico = {nombre:"-", apellidos:""}}
      if(doc.data().nifConsultor !=="" && doc.data().nifConsultor !== undefined){
        promises.push(firestore.collection('consultores').doc(doc.data().nifConsultor).get().then(doc => {
          consultor= doc.data()
        }))
      }else{consultor = {nombre:"-", apellidos:""}}
      if(doc.data().nifComercial !=="" && doc.data().nifComercial !== undefined){
        promises.push(firestore.collection('comerciales').doc(doc.data().nifComercial).get().then(doc => {
          comercial= doc.data()
        }))
      }else{comercial = {nombre:"-", apellidos:""}}
      Promise.all(promises).then(() => {
        this.setState({ tecnico: tecnico, consultor: consultor, comercial: comercial, hojasVisita:hojasVisita, centrosTrabajo:centrosTrabajo })
      })
    }).catch(err => window.location.replace("/aaff"))
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).orderBy('razonSocial').get().then(snapshot => {
      let data = []
      snapshot.docs.forEach(e => {
        if(e.data().centrosTrabajo !== undefined && e.data().centrosTrabajo.length !== 0){
          e.data().centrosTrabajo.sort((a,b) => {return a.nombre>b.nombre ? 1 : a.nombre<b.nombre ? -1 : 0} )
          //console.log(e.data())
        }
        data.push(e.data())
      })
      console.log(data)
      this.setState({ fincas: data })
    })
    firestore.collection("aaff").orderBy("razonSocial").get().then(snapshot => {
      let aaffData = []
      snapshot.docs.forEach(aaff => { 
        aaffData.push(aaff.data())
      })
      this.setState({ aaffs: aaffData })
    })
  }

  subirListado = (res) => {
    toast.info("Subiendo fincas...")
    let promises = []
    let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
    console.log(this.props.params.nif)
    firestore.collection("aaff").doc(this.props.params.nif).set({ 
      fechaEncargo: new Date()
    }, { merge: true })
    res.forEach(json => {
      if (regexp.test(json.nif)) {
        promises.push(firestore.collection("fincas").doc(json.nif?.toUpperCase()).set({
          activo: true,
          codigoPostal: json.codigoPostal || "",
          contacto: {
            nombre: json.contacto_nombre?.toUpperCase() || "",
            email: json.contacto_email || "",
            telefono: json.contacto_telefono || 0
          },
          cumplimiento: false,
          direccion: json.direccion?.toUpperCase() || "",
          documentos: {
            evaluacionRiesgos: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            hojaVisita: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            lopd: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            planEmergencia: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            prevencionRiesgos: {
              ruta: "",
              validado: false,
              pendiente: false
            }
          },
          estado: 0,
          fechaAlta: new Date(),
          ultimaReclamacion: new Date(),
          municipio: json.municipio?.toUpperCase() || "",
          nif: json.nif?.toUpperCase(),
          nifAAFF: json.nifAAFF?.toUpperCase(),
          proveedores: [],
          nifProveedores: [],
          provincia: json.provincia?.toUpperCase() || "",
          razonSocial: json.razonSocial?.toUpperCase() || ""
        }))
      } else {
        toast.error("NIF incorrecto: " + json.nif)
      }
    });
    Promise.all(promises).then(resp => {
      toast.info("Listado subido con éxito")
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al cargar la lista")
      console.log(err)
    })
  }

  subirListadoProveedores = (res) => {
    toast.info("Subiendo proveedores...")
    let promises = []
    let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
    res.forEach(json => {
      if (regexp.test(json.nif)) {
        promises.push(firestore.collection("proveedores").doc(json.nif).get().then(prov => {
          if (prov.exists) {
            let proveedor = prov.data()
            promises.push(firestore.collection("proveedores").doc(json.nif).update({
              codigoPostal: json.codigoPostal || proveedor.codigoPostal,
              contacto: {
                nombre: json.contacto_nombre?.toUpperCase() || proveedor.contacto.nombre,
                email: json.contacto_email || proveedor.contacto.email,
                telefono: json.contacto_telefono || proveedor.contacto.telefono
              },
              direccion: json.direccion?.toUpperCase() || proveedor.direccion,
              municipio: json.municipio?.toUpperCase() || proveedor.municipio,
              provincia: json.provincia?.toUpperCase() || proveedor.provincia,
              telefono: json.telefono || proveedor.telefono,
              razonSocial: json.razonSocial?.toUpperCase() || proveedor.razonSocial
            }))
          } else {
            promises.push(firestore.collection("proveedores").doc(json.nif).set({
              codigoPostal: json.codigoPostal || "",
              contacto: {
                nombre: json.contacto_nombre?.toUpperCase() || "",
                email: json.contacto_email || "",
                telefono: json.contacto_telefono || 0
              },
              direccion: json.direccion?.toUpperCase() || "",
              documentos: {
                declaracionResponsable: {},
                evaluacionRiesgos: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                justificante: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                lopd: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                certificadoSS: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                planificacionPreventiva: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                seguro: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                },
                vigilancia: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                }
              },
              estado: 0,
              fechaAlta: new Date(),
              emailLogin: json.emailLogin,
              municipio: json.municipio?.toUpperCase() || "",
              provincia: json.provincia?.toUpperCase() || "",
              nif: json.nif,
              telefono: json.telefono || 0,
              razonSocial: json.razonSocial?.toUpperCase() || ""
            }))
          }
        }))
      } else {
        toast.error("NIF incorrecto: " + json.nif)
      }
    });
    Promise.all(promises).then(resp => {
      toast.info("Listado subido con éxito")
    }).catch(err => {
      toast.error("Error al cargar la lista")
      console.log(err)
    })
  }

  subirListadoMatcheo = async (res) => {
    toast.info("Procesando matcheo...")
    let fincasInexistentes = []
    let proveedoresInexistentes = []
    let emailProveedores = []
    for (const json of res) {
      if(json.nifFinca === undefined){
        toast.error("¡OJO! nif de proveedor vacio")
      }
      const finca = await firestore.collection("fincas").doc(json.nifFinca).get()
      const hojasVisita = await firestore.collection("hojasVisita").doc(json.nifFinca).get()
      if (finca.exists) {
        if(json.nifProveedor === undefined){
          toast.error("¡OJO! nif de proveedor vacio")
        }
        const prov = await firestore.collection("proveedores").doc(json.nifProveedor).get()
        if (prov.exists) {
          emailProveedores.push(prov.data().emailLogin)
          firestore.collection("fincas").doc(json.nifFinca?.toUpperCase()).update({
            proveedores: firebase.firestore.FieldValue.arrayUnion({ nif: json.nifProveedor?.toUpperCase(), servicio: parseInt(json.servicio || 0) }),
            nifProveedores: firebase.firestore.FieldValue.arrayUnion(json.nifProveedor?.toUpperCase())
          })
          if(hojasVisita.exists){
            firestore.collection("hojasVisita").doc(json.nifFinca?.toUpperCase()).set({
              comunidad:{proveedores: firebase.firestore.FieldValue.arrayUnion({ nif: json.nifProveedor?.toUpperCase(), servicio: parseInt(json.servicio || 0) })}
            }, { merge: true })
          }
        } else {
          proveedoresInexistentes.push(json.nifProveedor)
        }
      } else {
        fincasInexistentes.push(json.nifFinca)
      }
      toast.info("Listado procesado con éxito")
      /* const actualizarPlanEmergencia = firebase.app().functions('europe-west1').httpsCallable('actualizarPlanEmergencia');
      actualizarPlanEmergencia({ finca: json.nifFinca })
      .then( result => {
        console.log(json.nifFinca, result.data);
      }) */
      const hv = await firestore.collection('hojasVisita').doc(json.nifFinca).get()
      if(hv.exists){
        functions.httpsCallable('actualizarPlanEmergencia')({ finca: json.nifFinca })
      }
      if (fincasInexistentes.length || proveedoresInexistentes.length !== 0) {
        toast.error("No se han podido hacer ciertos matcheos porque los siguientes NIFs no están dados de alta:", { autoClose: false })
        toast.error("FINCAS: " + fincasInexistentes.toString(), { autoClose: false })
        toast.error("PROVEEDORES: " + proveedoresInexistentes.toString(), { autoClose: false })
      }
      functions.httpsCallable('notificarProvNuevoAAFF')({ proveedores: emailProveedores })
    }
  }

  subirListadoRenovacion = async (res) => {
    toast.info("Procesando renovaciones...")
    let fincasInexistentes = []
    for (const json of res) {
      const finca = await firestore.collection("fincas").doc(json.nifFinca).get()
      if(finca.exists){
        this.renovarComunidad(json.nifFinca)
      }else{
        fincasInexistentes.push(json.nifFinca)
      }
      if (fincasInexistentes.length !== 0) {
        toast.error("FINCAS INEXISTENTES: " + fincasInexistentes.toString(), { autoClose: false })
      }
    }
  }

  validarDocumentacion = () => {
    firestore.doc(`fincas/${this.state.dialog.finca.nif}`).update({
      estado: firebase.firestore.FieldValue.increment(this.state.dialog.docName === "lopd" ? 5 : (this.state.dialog.docName === "hojaVisita" ? 20 : (this.state.dialog.docName === "evaluacionRiesgos" ? 25 : 0))),
      documentos: {
        ...this.state.dialog.finca.documentos,
        [this.state.dialog.docName]: {
          ...this.state.dialog.finca.documentos[this.state.dialog.docName],
          validado: true,
          pendiente: false
        }
      }
    }).then(() => {
      this.setState({ dialog: { opened: false, title: "", docName: "", finca: undefined } })
      this.loadPageContent()
    })

  }

  noValidarDocumentacion = () => {
    firestore.doc(`fincas/${this.state.dialog.finca.nif}`).set({
      documentos: {
        [this.state.dialog.docName]: {
          ruta: "",
          validado: false,
          pendiente: false
        }
      }
    }, { merge: true }).then(() => {
      this.setState({ dialog: { opened: false, title: "", docName: "", finca: undefined } })
      this.loadPageContent()
    })

  }

  validarDocumentacionCentroTrabajo = () => {
    let toRemove = []
    toRemove.push(this.state.dialogCentroTrabajo.ct)
    let ruta = this.state.dialogCentroTrabajo.ct.documentos[this.state.dialogCentroTrabajo.docName].ruta
    const docAct = {...this.state.dialogCentroTrabajo.ct.documentos, [this.state.dialogCentroTrabajo.docName]: {validado: true, pendiente: false, ruta: ruta}}
    firestore.collection('fincas').doc(this.state.dialogCentroTrabajo.finca.nif).set({
      centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
    }, { merge: true })
    firestore.doc(`fincas/${this.state.dialogCentroTrabajo.finca.nif}`).set({
      centrosTrabajo:
      firebase.firestore.FieldValue.arrayUnion({
        nombre: this.state.dialogCentroTrabajo.ct.nombre,
        nif: this.state.dialogCentroTrabajo.ct.nif,
        documentos: docAct
      })
    }, { merge: true }).then(() => {
      this.setState({ dialogCentroTrabajo: { opened: false, title: "", docName: "", finca: undefined, ct: undefined } })
      this.loadPageContent()
    })

  }

  noValidarDocumentacionCentroTrabajo = () => {
    let toRemove = []
    toRemove.push(this.state.dialogCentroTrabajo.ct)
    const docAct = {...this.state.dialogCentroTrabajo.ct.documentos, [this.state.dialogCentroTrabajo.docName]: {validado: false, pendiente: false, ruta: ''}}
    firestore.collection('fincas').doc(this.state.dialogCentroTrabajo.finca.nif).set({
      centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
    }, { merge: true })
    firestore.doc(`fincas/${this.state.dialogCentroTrabajo.finca.nif}`).set({
      centrosTrabajo:
      firebase.firestore.FieldValue.arrayUnion({
        nombre: this.state.dialogCentroTrabajo.ct.nombre,
        nif: this.state.dialogCentroTrabajo.ct.nif,
        documentos: docAct
      })
    }, { merge: true }).then(() => {
      this.setState({ dialogCentroTrabajo: { opened: false, title: "", docName: "", finca: undefined, ct: undefined } })
      this.loadPageContent()
    })

  }

  descargar = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  eliminarAAFF = () => {
    //toast.info("Borrando...", { showSpinner: true, autoClose: this.state.eliminarAAFF.duracion })
    let duracion
    this.setState({ eliminarAAFF: { opened: false, aaff: undefined } })
    
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).get().then(snapshot => {
      let nifFincas = []
      let nifProveedores = []
      let Proveedor = []
      let i = 0
      let j = null
      snapshot.docs.forEach(e => {
        nifFincas = nifFincas.concat(e.data().nif)
        nifProveedores = nifProveedores.concat(e.data().nifProveedores)
        if(nifFincas.length<=10){
          duracion = 3000
        }else{
          duracion = 500
        }
      })
      nifFincas = [...new Set(nifFincas)]
      nifProveedores = [...new Set(nifProveedores)]
      let promises = []
      let w=0
      nifProveedores.forEach(e => {
        
        promises.push(firestore.collection("fincas").where("nifProveedores", "array-contains", e).get().then(provs => {
          i=0
          //muestra todas las fincas que contenga algun proveedor de la lista nifProveedores
          provs.docs.forEach(prov => {
            //console.log(prov.data())

            /* prov.data().proveedores.forEach(nifp =>{
              //proveedores.push({niffinca: prov.data().nif, nifprov: nifp.nif, servicio: nifp.servicio}) 

              if(nifp.nif === e && this.props.params.nif === prov.data().nifAAFF){
                console.log("desvincular proveedor: "+nifp.nif+" de la finca: "+prov.data().nif+" el servicio: "+nifp.servicio)
                //desvincular proveedor
                firestore.collection("fincas").doc(prov.data().nif).set({
                  nifProveedores: firebase.firestore.FieldValue.arrayRemove(nifp.nif),
                  proveedores: firebase.firestore.FieldValue.arrayRemove({ 'nif': nifp.nif, 'servicio': nifp.servicio })
                }, { merge: true }).then(() => {
                  toast.success("Proveedor desvinculado con éxito")
                  this.loadPageContent()
                })
              }
            }) */
            //Si un proveedor da servicio a un AAFF que no queremos borrar entonces incrementamos i
            if(this.props.params.nif !== prov.data().nifAAFF){
              i++
            }
          })

          //Si i es 0 significa que solo da servicio al AAFF que queremos borrar por tanto borramos el proveedor
          if(i=== 0){
            j="borrar"
            Proveedor = Proveedor.concat({nifprov: e, j: j, i: i}) 
            setTimeout(() => {
              promises.push(console.log(w+" borramos el proveedor: "+ e))
              w++
            }, nifFincas.length*duracion);
            
            //borrar los archivos de los proveedores antes de borrarlos
            firestore.collection("proveedores").doc(e).get().then(snapshot => {
              if(snapshot.data().documentos.lopd?.ruta !== '' && snapshot.data().documentos.lopd?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.lopd?.ruta)
              }
              if(snapshot.data().documentos.seguro?.ruta !== '' && snapshot.data().documentos.seguro?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.seguro?.ruta)
              }
              if(snapshot.data().documentos.justificante?.ruta !== '' && snapshot.data().documentos.justificante?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.justificante?.ruta)
              }
              if(snapshot.data().documentos.planificacionPreventiva?.ruta !== '' && snapshot.data().documentos.planificacionPreventiva?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.planificacionPreventiva?.ruta)
              }
              if(snapshot.data().documentos.evaluacionRiesgos?.ruta !== '' && snapshot.data().documentos.evaluacionRiesgos?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.evaluacionRiesgos?.ruta)
              }
              if(snapshot.data().documentos.certificadoSS?.ruta !== '' && snapshot.data().documentos.certificadoSS?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.certificadoSS?.ruta)
              }
              if(snapshot.data().documentos.vigilancia?.ruta !== '' && snapshot.data().documentos.vigilancia?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.vigilancia?.ruta)
              }
              if(snapshot.data().documentos.declaracionResponsable?.[this.props.params.nif]?.ruta !== '' && snapshot.data().documentos.declaracionResponsable?.[this.props.params.nif]?.ruta !== undefined){
                this.borrarArchivo(snapshot.data().documentos.declaracionResponsable?.[this.props.params.nif]?.ruta)
              }
            })
            
            //borrar proveedores

            setTimeout(() => {
              firestore.collection("proveedores").doc(e).delete().then(() => {
                //toast.success("Proveedor"+ e +"eliminado con éxito")
              }).catch(err => {
                toast.error("Error al eliminar el proveedor "+ e, { autoClose: false })
                /* Swal.fire({
                  text: 'Error al eliminar el proveedor '+ e,
                  icon: 'error',
                  confirmButtonText: 'OK'
                }) */
                console.log(err)
              })
            }, nifFincas.length*duracion);
            /* Proveedor = [...new Set(Proveedor)] */
          }/* else{
            j="desvincular"
            Proveedor = Proveedor.concat({nifprov: e, j: j, i: i}) 
          } */
          //Proveedor = Proveedor.concat({...proveedores, j: j, i: i}) 
          
          Proveedor = [...new Set(Proveedor)]
        }))
      })
      let h=0
      // borrar los archivos de las fincas antes de borrar las fincas
      nifFincas.forEach(nf=>{
        setTimeout(() => {
          console.log(h+" borrar "+nf)
          h++
        }, nifFincas.length*duracion);
        firestore.collection("fincas").doc(nf).get().then(snapshot => {
          if(snapshot.data().documentos.lopd?.ruta !== '' && snapshot.data().documentos.lopd?.ruta !== undefined){
            this.borrarArchivo(snapshot.data().documentos.lopd?.ruta)
          }
          if(snapshot.data().documentos.hojaVisita?.ruta !== '' && snapshot.data().documentos.hojaVisita?.ruta !== undefined){
            this.borrarArchivo(snapshot.data().documentos.hojaVisita?.ruta)
          }
          if(snapshot.data().documentos.planEmergencia?.ruta !== '' && snapshot.data().documentos.planEmergencia?.ruta !== undefined){
            this.borrarArchivo(snapshot.data().documentos.planEmergencia?.ruta)
          }
          if(snapshot.data().documentos.prevencionRiesgos?.ruta !== '' && snapshot.data().documentos.prevencionRiesgos?.ruta !== undefined){
            this.borrarArchivo(snapshot.data().documentos.prevencionRiesgos?.ruta)
          }
          if(snapshot.data().documentos.evaluacionRiesgos?.ruta !== '' && snapshot.data().documentos.evaluacionRiesgos?.ruta !== undefined){
            this.borrarArchivo(snapshot.data().documentos.evaluacionRiesgos?.ruta)
          }
        })

        //borrar las fincas
        
        setTimeout(() => {
          firestore.collection("fincas").doc(nf).delete().then(() => {
            //toast.success("Finca "+ nf +" eliminado con éxito")
          }).catch(err => {
            toast.error("Error al eliminar la finca "+ nf, { autoClose: false })
            /* Swal.fire({
              text: 'Error al eliminar la finca '+ nf,
              icon: 'error',
              confirmButtonText: 'OK'
            }) */
            console.log(err)
          }) 
        }, nifFincas.length*duracion);
        
      })
      //borrar AAFF
      
       setTimeout(() => {
        console.log("borrar aaff "+ this.state.aaff.razonSocial)
      }, nifFincas.length*duracion);
      setTimeout(() => {
        firestore.collection("aaff").doc(this.props.params.nif).delete().then(() => {
          //toast.success("AAFF "+ this.state.aaff.razonSocial +" eliminado con éxito")
        }).catch(err => {
          toast.error("Error al eliminar el AAFF "+ this.state.aaff.razonSocial, { autoClose: false })
          /* Swal.fire({
            text: 'Error al eliminar el AAFF '+ this.state.aaff.razonSocial,
            icon: 'error',
            confirmButtonText: 'OK'
          }) */
          console.log(err)
        })
      }, nifFincas.length*duracion);
      Promise.all(promises).then(() => {
        //console.log(nifProveedores)
        //console.log(Proveedor)
        //console.log(nifFincas)
        this.setState({ eliminarAAFF: { duracion: nifFincas.length*duracion } })
        Swal.fire({
          position: 'top-end',
          title: 'Borrando...',
          timer: nifFincas.length*duracion,
          timerProgressBar: false,
          width: 200,
          customClass: {
            popup: 'swal-borrar'
          },
          didOpen: () => {
            Swal.showLoading()
            
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            toast.success("Finalizado el borrado", { autoClose: false })
            setTimeout(() => {
              toast.info("redirigir a")
              this.loadPageContent()
            }, 3000);
          }
        })
        
      })
      
    })
    
  }
  borrarArchivo = (ruta) => {
    // Por hacer
    let nombreArchivo = ruta.split('/')
    //toast.info("Borrando archivo...")
    storage.ref(ruta+"/").delete().then(url => {
      //toast.success("Archivo "+nombreArchivo[1]+" borrado")
      console.log("archivo: "+nombreArchivo[1]+" borrado")
    }).catch(error => {
      console.log(error)
      toast.error("Error al eliminar el archivo "+ nombreArchivo[1], { autoClose: false })
      /* Swal.fire({
        text: 'Error al eliminar el archivo '+ nombreArchivo[1],
        icon: 'error',
        confirmButtonText: 'OK'
      }) */
    })
    
  }
  eliminarFincas = () => {
    toast.info("Borrando fincas...")
    let promises = []
    promises.push(firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).get().then(snapshot => {
      snapshot.docs.forEach(finca => {
        promises.push(firestore.collection("fincas").doc(finca.data().nif).delete())
      })
    }))
    Promise.all(promises).then(() => {
      toast.success("Fincas eliminadas con éxito")
      this.setState({ eliminarFincas: false })
      this.loadPageContent()
    })
  }
  eliminarFinca = () => {
    firestore.collection("fincas").doc(this.state.eliminarFinca.finca.nif).delete().then(() => {
      toast.success("Finca eliminada con éxito")
      this.setState({ eliminarFinca: { opened: false, finca: undefined } })
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al eliminar la finca")
      console.log(err)
    })
  }
  eliminarCentroTrabajo = () => {
    let toRemove = []
    toRemove.push(this.state.eliminarCentroTrabajo.ct)
    firestore.collection('fincas').doc(this.state.eliminarCentroTrabajo.finca.nif).set({
      centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
    }, { merge: true }).then(() => {
      toast.success("Centro de trabajo eliminado correctamente")
      this.setState({ eliminarCentroTrabajo: { opened: false, ct: undefined, finca: undefined } })
      this.loadPageContent()
    }).catch((err) => {
      toast.error("Ha ocurrido un error")
      console.log(err)
    })
  }

  generarInformeProveedor = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-proveedores`
  }

  generarInformeRiesgos = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-riesgos`
  }

  generarInformeComunidades = () => {
    toast.info("Generando informe...")
    var iFrame = document.getElementById('iFramePdf');
    iFrame.src = `/doc-render/${this.props.params.nif}/situacion-comunidades`
  }

  Activo = (evt, nif) => {
    const value = evt.target.value;
    firestore.collection("fincas").doc(nif).set({ activo: value }, { merge: true }).then(() => {
      toast.success("Finca actualizada correctamente")
      this.loadPageContent()
    }).catch((err) => {
      toast.error("Ha ocurrido un error")
      console.log(err)
    })
  }
  empleados = (evt, nif) => {
    const value = evt.target.value;
    firestore.collection("fincas").doc(nif).set({ empleados: value }, { merge: true }).then(() => {
      toast.success("Finca actualizada correctamente")
      this.loadPageContent()
    }).catch((err) => {
      toast.error("Ha ocurrido un error")
      console.log(err)
    })
  }
  servicios = (evt, nif) => {
    const value = evt.target.value;
    firestore.collection("fincas").doc(nif).set({ servicios: value}, { merge: true }).then(() => {
      toast.success("Finca actualizada correctamente")
      this.loadPageContent()
    }).catch((err) => {
      toast.error("Ha ocurrido un error")
      console.log(err)
    })
  }
  mostraraaff = (evt) => {
    this.setState({aaffBuscado: evt.target.value})
    let nuevaLista = []
    this.state.aaffs.map((j, i) => {
      if(j.nif.toString().includes(evt.target.value)){
        //console.log(j.nif+' - '+j.razonSocial)
        //nuevaLista.push(j)
        nuevaLista.push({nif: j.nif, razonSocial: j.razonSocial})
      }
    })
    if(nuevaLista.length === 1){
      nuevaLista.forEach(l => {
        this.setState({aaffSeleccionado: l.nif})
      })
    }
    this.state.nuevaLista=[]
    this.setState({nuevaLista : nuevaLista})
    if(nuevaLista.length === 1){
    }
    this.setState({aaffRazonSocialSeleccionado: nuevaLista[0].razonSocial})
    //this.setState({aaffs : nuevaLista})
    //console.log(this.state.aaffs)
    console.log(nuevaLista[0].razonSocial)
  }
  seleccionaraaff = (evt) => {
    let RazonSocial = evt.target.selectedOptions[0].dataset.name
    // console.log("AAFF SELECCIONADO: "+ evt.target.value, RazonSocial)
    this.setState({aaffSeleccionado: evt.target.value, aaffRazonSocialSeleccionado: RazonSocial})
    
  }
  cambiaraaff = () => {
    console.log(this.state.aaffRazonSocialSeleccionado)
    let nuevoaaff = null
    if(this.state.aaffSeleccionado === null){
      nuevoaaff = this.state.aaff.nif
    }else{
      nuevoaaff = this.state.aaffSeleccionado
    }
    firestore.collection("fincas").doc(this.state.cambiarAAFF.finca.nif).update({
      nifAAFF: nuevoaaff
      
    }).then(() => {
      firestore.collection('hojasVisita').doc(this.state.cambiarAAFF.finca.nif).get().then((doc) => {
        if (doc.exists) {
          firestore.collection('hojasVisita').doc(this.state.cambiarAAFF.finca.nif).update({
            aaff:{
              nif: this.state.aaffSeleccionado,
              razonSocial: this.state.aaffRazonSocialSeleccionado
            }
          }).then(() => {
            toast.success("AAFF cambiado con éxito")
            this.setState({ cambiarAAFF: { opened: false, finca: undefined } })
            this.loadPageContent()
          }).catch(err => {
            console.log(err)
            toast.error("error al cambiar aaff en hojavisita")
          })
        }
      })
    }).catch(err => {
      console.log(err)
      toast.error("Error al cambiar de AAFF")
    })
    
    //console.log(this.state.cambiarAAFF.finca.nif, "aaffSeleccionado: "+nuevoaaff)
  }
  ordenarActivas = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (a.activo === true || a.activo === "true" || a.activo === undefined) {
        return 1; 
      }
      if (b.activo === true || b.activo === "true" || b.activo === undefined) {
        return -1; 
      }
      
      if (a.activo > b.activo) {
        return 1;
      }
      if (a.activo < b.activo) {
        return -1;
      }
      return 0;
    })
    //console.log(fincas)
    this.setState({ fincas: fincas })
  }
  ordenarEmpleados = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (a.empleados === true || a.empleados === "true") {
        return -1; 
      }
      if (b.empleados === true || b.empleados === "true") {
        return 1; 
      }
      
      if (a.empleados > b.empleados) {
        return 1;
      }
      if (a.empleados < b.empleados) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }
  
  ordenarServicios = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      if (a.servicios === true || a.servicios === "true") {
        return -1; 
      }
      if (b.servicios === true || b.servicios === "true") {
        return 1; 
      }
      
      if (a.servicios > b.servicios) {
        return 1;
      }
      if (a.servicios < b.servicios) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }
  /* mensajeBienvenida = () => {
    let promises = []
    let aaffRazonSocial = ''
    let nifaaff = ''
    let proveedores = []
    let nifProveedores = []
    let finca = this.state.fincas[0]
    let fincasRazonSocial = []
    
   //  this.state.fincas.forEach( f => {
    //  nifProveedores = nifProveedores.concat(f.nifProveedores)
      
   // })
   // nifProveedores = [...new Set(nifProveedores)] 

    firestore.collection("fincas").where("nifAAFF", "==", finca.nifAAFF).get().then( snapshot => {
      snapshot.docs.forEach(f =>  nifProveedores = nifProveedores.concat(f.data().nifProveedores))
      nifProveedores = [...new Set(nifProveedores)]
      
      promises.push(firestore.collection("aaff").doc(finca.nifAAFF).get().then(aaffRS => aaffRazonSocial = aaffRS.data().razonSocial),
        nifProveedores.forEach(proveedor => {
          
          firestore.collection("fincas").where("nifProveedores", "array-contains", proveedor).where("nifAAFF", "==", finca.nifAAFF).get().then(res => {
            let nifFincas = []
            res.docs.forEach( fin => {
              nifFincas.push({nif: fin.data().nif, razonSocial: fin.data().razonSocial})  
            })
            fincasRazonSocial = nifFincas
          })
          promises.push(firestore.collection("proveedores").doc(proveedor).get().then(p => {
            var diasdif= new Date().getTime()-new Date(p.data().fechaAlta.seconds * 1000).getTime();
            var contdias = Math.round(diasdif/(1000*60*60*24));
            if(contdias === 3){
              proveedores.push({...p.data(), fincas: fincasRazonSocial})
            }
          }))
        })
      )
      Promise.all(promises).then(() => {
        //console.log(arrFincas)
        //console.log(proveedores)
        //console.log(fincasRazonSocial)
        proveedores.forEach(prov => {
          //let fincanif = []
          //let fincaRS = []
          let arrFincas = []
          for(const key in prov.fincas){
            // fincanif.push(prov.fincas[key].nif)
            //fincaRS.push(prov.fincas[key].razonSocial)
            //console.log(prov.fincas[key])
            arrFincas.push({nif: prov.fincas[key].nif, razonSocial: prov.fincas[key].razonSocial})
          }
          console.log("Buenos días,\n El administrador de fincas "+aaffRazonSocial+" nos ha contratado para realizar la coordinación de actividades empresariales en sus comunidades dado que prestáis servicios de mantenimiento en las mismas. Por ello, hemos procedido a darle de alta en la siguiente plataforma:")
          console.log("AAFFRAZONSOCIAL: "+aaffRazonSocial)
          console.log("Usuario: "+prov.emailLogin)
          console.log("Contraseñoa: "+prov.nif)
      
          console.log("Las comunidades en las que prestáis servicios de mantenimiento son las siguientes:")
          //console.log(fincanif)
          //console.log(fincaRS) 
          console.log(arrFincas)
        })
          //console.log("email finca: "+finca.nif)
      
      })  
    })
      
  } */

  cambiarObservaciones = (evt) => {
    let value = evt.target.value
    this.setState({observaciones: value});
  }
  guardarObservaciones = () => {
    firestore.collection('aaff').doc(this.state.aaff.nif).update({
      observacionesTecnico: this.state.observaciones
    }).then(() => {
      toast.success("Observaciones guardadas")
    }).catch(err => {
      toast.error("Error al guardar")
      console.log(err)
    })

  }
  
  renovarComunidades = (nifAaff, nombre) => {
    let fincas = []
    let centrosTrabajo = []
    let promises = []
    console.log("todas las fincas renovadas de "+ nifAaff)
    promises.push(firestore.collection('fincas').where('nifAAFF', '==', nifAaff).orderBy('razonSocial').get().then(a => {
      a.docs.forEach((f, i)=>{
        fincas.push(f.data())
        if(f.data().centrosTrabajo !== undefined){
          for(let x=0; x<f.data().centrosTrabajo.length; x++){
            centrosTrabajo.push(f.data()?.centrosTrabajo[x].nif)
          }
        }
      })
    }))
    Promise.all(promises).then(() => {
      //comprobamos si la finca tiene centros de trabajo. Si tiene lo comparamos con el array de nifs de centros de trabajo y si existe lo eliminamos y lo creamos de nuevo con los campos renovada y renovadaVisitada 
      
      Swal.fire({
        title: nombre,
        text: "¿Quieres renovar todas las comunidades y centros de trabajo de este aaff?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Renovar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let ctNif = centrosTrabajo
          fincas.forEach(f => {
            firestore.doc(`fincas/${f.nif}`).set({
              renovada:true,
              renovadaVisitada: false,
              fechaRenovacion: new Date()
            }, { merge: true }).then(() => {
              toast.success("Finca "+ f.nif + " renovada")
            }).catch(err => {
              toast.error("Ha ocurrido un error " + err)
            })
            if(f.centrosTrabajo !== undefined){
              let ct = f.centrosTrabajo
              let cLength = f.centrosTrabajo.length
              firestore.collection('fincas').doc(f.nif).set({
                centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...f.centrosTrabajo)
              }, { merge: true })
              for(let x=0; x<cLength; x++){
                if(ctNif.includes(ct[x].nif)){
                  console.log({...ct[x], renovada:true, renovadaVisitada:false})
                  firestore.doc(`fincas/${f.nif}`).set({
                    centrosTrabajo:
                    firebase.firestore.FieldValue.arrayUnion({
                      documentos: ct[x].documentos,
                      nombre: ct[x].nombre,
                      nif: ct[x].nif,
                      renovada: true,
                      renovadaVisitada: false
                    })
                  }, { merge: true }).then(() => {
                    toast.success("centro de trabajo "+ ct[x].nif + " renovado")
                  }).catch(err => {
                    toast.error("Ha ocurrido un error " + err)
                  })
                }
              }
            }
          })
        }
      })
    })
  }
  renovarComunidad = (nifFinca) => {
    let finca = {}
    let centrosTrabajo = []
    let ctNif = []
    let promises = []
    promises.push(firestore.collection('fincas').doc(nifFinca).get().then(a => {
      if(a.data().centrosTrabajo !== undefined){
        finca = a.data()
        centrosTrabajo = a.data().centrosTrabajo
        for(let x=0; x<a.data().centrosTrabajo.length; x++){
          ctNif.push(a.data()?.centrosTrabajo[x].nif)
        }
      }
    }))
    Promise.all(promises).then(() => {
      firestore.doc(`fincas/${nifFinca}`).set({
        renovada:true,
        renovadaVisitada: false,
        fechaRenovacion: new Date()
      }, { merge: true }).then(() => {
        toast.success("Finca "+ nifFinca + " renovada")
      }).catch(err => {
        toast.error("Ha ocurrido un error " + err)
      })
      if(finca.centrosTrabajo !== undefined){
        let ct = finca.centrosTrabajo
        let cLength = finca.centrosTrabajo.length
        /* console.log(ct)
        console.log(cLength) */
        firestore.collection('fincas').doc(nifFinca).set({
          centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...finca.centrosTrabajo)
        }, { merge: true })
        for(let x=0; x<cLength; x++){
          if(ctNif.includes(ct[x].nif)){
            //console.log({...ct[x], renovada:true, renovadaVisitada:false})
            firestore.doc(`fincas/${nifFinca}`).set({
              centrosTrabajo:
              firebase.firestore.FieldValue.arrayUnion({
                documentos: ct[x].documentos,
                nombre: ct[x].nombre,
                nif: ct[x].nif,
                renovada: true,
                renovadaVisitada: false
              })
            }, { merge: true }).then(() => {
              toast.success("centro de trabajo "+ ct[x].nif + " renovado")
            }).catch(err => {
              toast.error("Ha ocurrido un error " + err)
            })
          }
        }
      }
      this.loadPageContent() 
    })
  }
  monthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //mostrar la diferencia en meses
  }

  renovarCentroTrabajo = (nifFinca, nifCt, nombre) => {
    let promises = []
    let finca = []
    let centrosTrabajo = []
    promises.push(firestore.collection('fincas').doc(nifFinca).get().then(a => {
      if(a.data().centrosTrabajo !== undefined){
        finca = a.data()
        for(let x=0; x<a.data().centrosTrabajo.length; x++){
          if(a.data()?.centrosTrabajo[x].nif === nifCt){
            centrosTrabajo.push(a.data()?.centrosTrabajo[x])
          }
        }
      }
    }))
    Promise.all(promises).then(() => {
      Swal.fire({
        title: nombre,
        text: "¿Quieres renovar el centro de trabajo "+nifCt+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Renovar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let ct = centrosTrabajo
          let cLength = centrosTrabajo.length
          console.log(ct)
          firestore.collection('fincas').doc(nifFinca).set({
            centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...centrosTrabajo)
          }, { merge: true })
          for(let x=0; x<cLength; x++){
            firestore.doc(`fincas/${nifFinca}`).set({
              centrosTrabajo:
              firebase.firestore.FieldValue.arrayUnion({
                documentos: ct[x].documentos,
                nombre: ct[x].nombre,
                nif: ct[x].nif,
                renovada: true,
                renovadaVisitada: false
              })
            }, { merge: true }).then(() => {
              toast.success("centro de trabajo "+ ct[x].nif + " renovado")
            }).catch(err => {
              toast.error("Ha ocurrido un error " + err)
            })
          }
        }
      })
    })
  }

  renovarComunidadSeleccionada = () => {
    let fecha = undefined
    console.log(this.state.fincasSeleccionadas)
    this.state.fincasSeleccionadas.forEach(f => {
      console.log(f)
      this.renovarComunidad(f)
      /* firestore.collection("hojasVisita").doc(f).get().then(fhv => { 
        if(fhv.exists){
          if(fhv.data().renovada){
            fecha = fhv.data().fechaRenovacion.seconds
          }else{
            fecha = fhv.data().fechaVisita.seconds
          }
          if(this.monthDiff(new Date(fecha*1000), new Date()) > 3){ 
            console.log("renovar comunidad "+ f)
          }else{ 
            console.log("no renovar comunidad "+f)
          }
        }else{
          console.log("no existe")
        }
      })    */
    })
  }


  
  seleccionarComunidad = (evt, nifFinca) => {
    if(evt){
      this.state.fincasSeleccionadas.push(nifFinca)
    }else{
      console.log("quitar finca: "+ nifFinca)
      if(this.state.fincasSeleccionadas.includes(nifFinca)){
        let res = this.state.fincasSeleccionadas.filter(item => item !== nifFinca)
        this.setState({fincasSeleccionadas: res})
      }
    }
  }

  render() {
    if (this.state.aaff === {}) return <></> //Loading data...
    return (
      <>
        {/* <button onClick={() => this.mensajeBienvenida()}>mensaje</button> */}
        <ModalEditar aaff={this.state.aaff} opened={this.state.modalEditarOpened} handleClose={() => {
          this.setState({ modalEditarOpened: false })
          this.loadPageContent()
        }} />
        <iframe title='pdf' id="iFramePdf" style={{ display: 'none' }}></iframe>
        <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Administrador / <b>{this.state.aaff.razonSocial}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              INFORMACIÓN GENERAL
              <Link to="/aaff"><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div className="gridSubhead editarSubhead">
              <div>DATOS EMPRESA</div>
              <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <img src={Editar} alt="Editar" /></div>
            </div>
            <div className="datosGrid">
              <p><b>Nombre AAFF:</b> {this.state.aaff.razonSocial}</p>
              <p><b>NIF:</b> {this.state.aaff.nif}</p>
              <p><b>Municipio:</b> {this.state.aaff.municipio}</p>
              <p><b>Provincia:</b> {this.state.aaff.provincia}</p>
              <p><b>Persona de contacto:</b> {this.state.aaff.contacto}</p>
              <p><b>Teléfono:</b> {this.state.aaff.telefono}</p>
              <p><b>Email:</b> {this.state.aaff.email}</p>
              <p><b>Consultor:</b> {this.state.consultor.nombre+ " "+this.state.consultor.apellidos} </p>
              <p><b>Técnico:</b> {this.state.tecnico.nombre+ " "+this.state.tecnico.apellidos} </p>
              <p><b>Comercial:</b> {this.state.comercial.nombre+ " "+this.state.comercial.apellidos} </p>
              <p><b>Fecha de encargo:</b> {this.state.aaff.fechaEncargo === undefined || this.state.aaff.fechaEncargo === ''  ? '-' : new Date(this.state.aaff.fechaEncargo.seconds*1000).toLocaleDateString()} </p>
              <p><b>Fecha de bienvenida:</b> {this.state.aaff.fechaBienvenida === undefined || this.state.aaff.fechaBienvenida === '' ? '-' : new Date(this.state.aaff.fechaBienvenida.seconds*1000).toLocaleDateString()} </p>
              <p><b>Fecha de Reclamación proveedores:</b> {this.state.aaff.fechaReclamacion === undefined || this.state.aaff.fechaReclamacion === '' ? '-' : new Date(this.state.aaff.fechaReclamacion.seconds*1000).toLocaleDateString()} </p>
              <p><b>Fecha informe proveedores:</b> {this.state.aaff.fechaInforme === undefined || this.state.aaff.fechaInforme === '' ? '-' : new Date(this.state.aaff.fechaInforme.seconds*1000).toLocaleDateString()} </p>
            </div>
            <div className="datosGridComentarios"><p><b>Comentarios:</b></p><p style={{ whiteSpace: "pre-line" }}> {this.state.aaff.comentarios === "" || this.state.aaff.comentarios === undefined ? "-" : this.state.aaff.comentarios} </p></div>
            <div className="datosGridComentarios"><p><b>Observaciones del técnico:</b></p>
              {/* <p style={{ whiteSpace: "pre-line" }}> 
                {this.state.aaff.observacionesTecnico === "" || this.state.aaff.observacionesTecnico === undefined ? "-" : this.state.aaff.observacionesTecnico} 
              </p> */}
              <form  noValidate autoComplete="off" style={{display:'flex', alignItems:'end'}}>
                  <textarea style={{marginRight:'10px', border: '1px solid #ccc', width:'100%'}} id="observaciones" name="observaciones" label="observaciones" value={this.state.observaciones} onChange={this.cambiarObservaciones} multiline rows={6} columns={100}/>
                  <div >
                    <Button className="btn-Secondary" variant="contained" color="secondary" onClick={this.guardarObservaciones}>Guardar</Button>
                  </div>
                </form>
            </div>
            
            <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: 'center', paddingLeft: 10, paddingRight: 10, margin: 10 }}>
              <div className="bigBtn" onClick={() => this.setState({ eliminarAAFF: { opened: true, aaff: this.state.aaff } })}><img src={Delete} alt="Eliminar" />ELIMINAR ADMINISTRADOR DE FINCAS</div>
            </div>
            <div className="gridSubhead">LISTADO COMUNIDADES</div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={5} style={{ padding: "20px 20px 30px 20px" }}>
              <Grid item xs={12} md={3}>
                <label htmlFor="listadoFincas"><div className="listadoBtn">Subir listado de Fincas</div></label><input type="file" id="listadoFincas" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={doc => { excelToJson(doc.target.files[0], (res) => this.subirListado(res)) }} className="inputfile" />
                <div className="descargarBtn" onClick={() => this.descargar("PlantillasExcel/plantillaFincas.xlsx")}><img src={Descargar} alt="Descargar" />Descargar plantilla de Fincas</div>
              </Grid>
              <Grid item xs={12} md={3}>
                <label htmlFor="listadoProveedores"><div className="listadoBtn">Subir listado de Proveedores</div></label><input type="file" id="listadoProveedores" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={doc => { excelToJson(doc.target.files[0], (res) => this.subirListadoProveedores(res)) }} className="inputfile" />
                <div className="descargarBtn" onClick={() => this.descargar("PlantillasExcel/plantillaProveedores.xlsx")}><img src={Descargar} alt="Descargar" />Descargar plantilla de Proveedores</div>
              </Grid>
              <Grid item xs={12} md={3}>
                <label htmlFor="listadoMatcheo"><div className="listadoBtn">Subir listado de Matcheo</div></label><input type="file" id="listadoMatcheo" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={doc => { excelToJson(doc.target.files[0], (res) => this.subirListadoMatcheo(res)) }} className="inputfile" />
                <div className="descargarBtn" onClick={() => this.descargar("PlantillasExcel/plantillaMatcheo.xlsx")}><img src={Descargar} alt="Descargar" />Descargar plantilla de Matcheo</div>
              </Grid>
              <Grid item xs={12} md={3}>
                <label htmlFor="listadoRenovacion"><div className="listadoBtn">Subir listado de Renovación</div></label><input type="file" id="listadoRenovacion" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={doc => { excelToJson(doc.target.files[0], (res) => this.subirListadoRenovacion(res)) }} className="inputfile" />
                <div className="descargarBtn" onClick={() => this.descargar("PlantillasExcel/plantillaRenovacion.xlsx")}><img src={Descargar} alt="Descargar" />Descargar plantilla de Renovación</div>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "left", alignItems: 'center' }}>
                <div className="bigBtn" onClick={this.generarInformeComunidades}><img src={Descargar} alt="GENERAR INFORME COMUNIDADES" />GENERAR INFORME COMUNIDADES</div>
                {/* <Link className="bigBtn" style={{color:'#fff !important'}} target="_blank" to={`/doc-render/${this.props.params.nif}/situacion-comunidades-pdf`}><img src={Descargar} alt="GENERAR INFORME COMUNIDADES" />GENERAR INFORME COMUNIDADES</Link> */}
                <div className="bigBtn" onClick={this.generarInformeProveedor}><img src={Descargar} alt="GENERAR INFORME PROVEEDORES" />GENERAR INFORME PROVEEDORES</div>
                {/* <Link className="bigBtn" style={{color:'#fff !important'}} target="_blank" to={`/doc-render/${this.props.params.nif}/situacion-proveedores-pdf`}><img src={Descargar} alt="GENERAR INFORME PROVEEDORES" />GENERAR INFORME PROVEEDORES</Link> */}
                <div className="bigBtn" onClick={this.generarInformeRiesgos}><img src={Descargar} alt="GENERAR INFORME RIESGOS" />GENERAR INFORME RIESGOS</div>
              </div>
              <div className="bigBtn" onClick={() => this.setState({ eliminarFincas: true })}><img src={Delete} alt="Eliminar" />ELIMINAR TODAS LAS FINCAS</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
              <div >
              {this.state.show ?
                <ExportarListaFincasAAFF aaffNif={this.state.aaff.nif} fincas={this.state.fincas} />
              :
                <button className='bigBtn' onClick={() => this.setState({show:true})}><img src={Excel} alt="Generar listado" width="30px"/> Generar listado</button>
              }
              </div>
              <div><Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => this.renovarComunidadSeleccionada()}>Renovar seleccionadas</Button></div>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th>Fecha alta</th>
                      <th style={{ textAlign: "center" }}>LOPD</th>
                      <th style={{ textAlign: "center" }}>Hoja de visita</th>
                      <th style={{ textAlign: "center" }}>Plan prevención</th>
                      <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                      <th style={{ textAlign: "center" }}>Plan emergencia</th>
                      <th style={{ textAlign: "center" }}>Proveedores</th>
                      <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarActivas}>Activa</th>
                      <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarServicios}>Servicios</th>
                      <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarEmpleados}>Empleados</th>
                      <th style={{ textAlign: "center" }} >Añadir centro trabajo</th>
                      {/* <th style={{ textAlign: "center" }} >Centros de trabajo</th> */}
                      <th style={{ textAlign: "center" }} >Cambiar AAFF</th>
                      <th style={{ textAlign: "center" }} >Renovar</th>
                      <th style={{ textAlign: "center" }}>Eliminar</th>
                      <th style={{ textAlign: "center" }}>No visitada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fincas.map((e, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td><Link to={"/aaff/" + this.props.params.nif + "/comunidades/"+ e.nif}>{e.razonSocial}</Link></td>
                            <td>{e.nif}</td>
                            <td>{new Date(e.fechaAlta.seconds *1000).toLocaleDateString()}</td>
                            <td style={{ textAlign: "center" }}>{e.documentos.lopd?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                              this.setState({ dialog: { opened: true, title: "¿Desea verificar la LOPD de " + e.razonSocial + "?", docName: "lopd", finca: e } })
                              abrirArchivo(e.documentos.lopd?.ruta)
                            }} /> :
                              <CustomSwitch checked={e.documentos.lopd?.validado} onClick={e.documentos.lopd?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'lopd', finca: e } }) : () => subirDocumentacionComunidad("lopd", e, this.loadPageContent)} />}
                              <small style={{display:'block'}}>{e.documentos.lopd?.fechaSubida !== undefined ? <span >{new Date(e.documentos.lopd?.fechaSubida?.seconds* 1000).toLocaleDateString()}</span> : null}</small>
                            </td> 
                            {e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0 ? 
                              <>
                                <td style={{ textAlign: "center" }}>{e.documentos.hojaVisita?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                  this.setState({ dialog: { opened: true, title: "¿Desea verificar la Hoja de Visita de " + e.razonSocial + "?", docName: "hojaVisita", finca: e } })
                                  abrirArchivo(e.documentos.hojaVisita?.ruta)
                                }} /> :
                                this.state.hojasVisita.includes(e.nif) ? 
                                  <CustomSwitch checked={e.documentos.hojaVisita?.validado} onClick={() => abrirPdf(e.nif, e.documentos.hojaVisita?.ruta)} />
                                : 
                                  <CustomSwitch checked={e.documentos.hojaVisita?.validado} onClick={e.documentos.hojaVisita?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'hojaVisita', finca: e } }) : () => subirDocumentacionComunidad("hojaVisita", e, this.loadPageContent)} />}</td>
                                <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                  this.setState({ dialog: { opened: true, title: "¿Desea verificar el Plan de Prevención de Riesgos de " + e.razonSocial + "?", docName: "prevencionRiesgos", finca: e } })
                                  abrirArchivo(e.documentos.prevencionRiesgos?.ruta)
                                }} /> :
                                  <CustomSwitch checked={e.documentos.prevencionRiesgos?.validado} onClick={e.documentos.prevencionRiesgos?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'prevencionRiesgos', finca: e } }) : () => subirDocumentacionComunidad("prevencionRiesgos", e, this.loadPageContent)} />}</td>
                                <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                  this.setState({ dialog: { opened: true, title: "¿Desea verificar la Identificación de Riesgos de " + e.razonSocial + "?", docName: "evaluacionRiesgos", finca: e } })
                                  abrirArchivo(e.documentos.evaluacionRiesgos?.ruta)
                                }} /> :
                                this.state.hojasVisita.includes(e.nif) ? 
                                  <CustomSwitch checked={e.documentos.evaluacionRiesgos?.validado} onClick={() => abrirPdf(e.nif, e.documentos.evaluacionRiesgos?.ruta)} />
                                : 
                                  <CustomSwitch checked={e.documentos.evaluacionRiesgos?.validado} onClick={e.documentos.evaluacionRiesgos?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'evaluacionRiesgos', finca: e } }) : () => subirDocumentacionComunidad("evaluacionRiesgos", e, this.loadPageContent)} />}</td>
                                <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                  this.setState({ dialog: { opened: true, title: "¿Desea verificar el Plan de Emergencia de " + e.razonSocial + "?", docName: "planEmergencia", finca: e } })
                                  abrirArchivo(e.documentos.planEmergencia?.ruta)
                                }} /> :
                                this.state.hojasVisita.includes(e.nif) ? 
                                  <CustomSwitch checked={e.documentos.planEmergencia?.validado} onClick={() => abrirPdf(e.nif, e.documentos.planEmergencia?.ruta)} />
                                : 
                                  <CustomSwitch checked={e.documentos.planEmergencia?.validado} onClick={e.documentos.planEmergencia?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'planEmergencia', finca: e } }) : () => subirDocumentacionComunidad("planEmergencia", e, this.loadPageContent)} />}</td>
                              </> 
                            : 
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            }
                            <td style={{ textAlign: "center" }}><Link to={`/aaff/${this.props.params.nif}/comunidades/${e.nif}/proveedores`}>{e.nifProveedores.length}</Link></td>
                            <td style={{ textAlign: "center" }}>
                              <form noValidate autoComplete="off">
                                <FormControl required style={{ width: '100%' }}>
                                  <Select
                                    native
                                    name="activo"
                                    onChange={event => this.Activo(event, e.nif)}
                                    value={e.activo === undefined ? true : e.activo}
                                  >
                                    <option value={true}>Activa</option>
                                    <option value={false}>Inactiva</option>

                                  </Select>
                                </FormControl>
                              </form>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <form noValidate autoComplete="off">
                                <FormControl required style={{ width: '100%' }}>
                                  <Select
                                    native
                                    name="servicios"
                                    onChange={event => this.servicios(event, e.nif)}
                                    value={e.servicios === undefined ? false : e.servicios}
                                  >
                                    <option value={false}>CAE</option>
                                    <option value={true}>SPA</option>

                                  </Select>
                                </FormControl>
                              </form>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <form noValidate autoComplete="off">
                                <FormControl required style={{ width: '100%' }}>
                                  <Select
                                    native
                                    name="empleados"
                                    onChange={event => this.empleados(event, e.nif)}
                                    value={e.empleados === undefined ? false : e.empleados}
                                  >
                                    <option value={false}>No</option>
                                    <option value={true}>Sí</option>

                                  </Select>
                                </FormControl>
                              </form>
                            </td>
                            <td style={{ textAlign: "center" }}><Link to={"/comunidades/"+e.nif+"/nuevoCentro"}><img src={Add} alt="Más" className="tableIcon"/></Link></td>
                            {/* <td style={{ textAlign: "center", cursor:"pointer" }} onClick={() => this.setState({expandir: !this.state.expandir})}>{e.centrosTrabajo === undefined ? 0 : e.centrosTrabajo.length}</td> */}
                            <td style={{ textAlign: "center" }}><img src={Change} alt="CAMBIAR AAFF" className="tableIcon" onClick={() => this.setState({ cambiarAAFF: { opened: true, finca: e } })}></img></td>
                            <td style={{ cursor:"pointer", textAlign: "center" }}>{e.renovada ? <img src={Tick} width="30px" alt="todas renovadas" /> : <input type="checkbox" onClick={evt => this.seleccionarComunidad(evt.target.checked, e.nif, e.razonSocial)} />}</td>
                            <td style={{ textAlign: "center" }}><img src={Delete} alt="ELIMINAR" className="tableIcon" onClick={() => this.setState({ eliminarFinca: { opened: true, finca: e } })}></img></td>
                            {e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0 ? 
                              <td style={{ textAlign: "center", width: 105 }}><NoVisitado finca={e} nif={e.nif} tipo={'finca'} /></td>
                            :
                              <td></td>
                            }
                          </tr>
                          {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null :
                            <>
                              {e.centrosTrabajo.map((c,j) => {
                                return(
                                  <tr key={j} style={{backgroundColor: j%2==0 ? "#fffbf4" : "#fff9ea" }}>
                                    <td></td>
                                    <td colSpan={3} width={'100px'}>{c.nombre}</td>
                                    <td></td>
                                    {/* <td style={{ textAlign: "center" }}>{c.documentos.lopd?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                      this.setState({ dialog: { opened: true, title: "¿Desea verificar la LOPD de " + c.razonSocial + "?", docName: "lopd", finca: e } })
                                      abrirArchivo(c.documentos.lopd?.ruta)
                                    }} /> :
                                      <CustomSwitch checked={c.documentos.lopd?.validado} onClick={c.documentos.lopd?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'lopd', finca: e } }) : () => subirDocumentacionCentroTrabajo("lopd", e, c.nif, this.loadPageContent)} />}</td> */}
                                    <td style={{ textAlign: "center", width:'100px' }}>{c.documentos.hojaVisita?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                      this.setState({ dialogCentroTrabajo: { opened: true, title: "¿Desea verificar la Hoja de Visita de " + c.razonSocial + "?", docName: "hojaVisita", finca: e, ct:c } })
                                      abrirArchivo(c.documentos.hojaVisita.ruta)
                                    }} /> :
                                    this.state.centrosTrabajo.includes(c.nif) ? 
                                      <CustomSwitch checked={c.documentos.hojaVisita?.validado} onClick={() => abrirPdfCt(e.nif, c.nif, c.documentos.hojaVisita?.ruta)} />
                                    :
                                      <CustomSwitch checked={c.documentos.hojaVisita?.validado} onClick={c.documentos.hojaVisita?.validado ? () => this.setState({ verDocumentacionCentroTrabajo: { opened: true, nombreDoc: 'hojaVisita', ct: c, finca: e } }) : () => subirDocumentacionCentroTrabajo("hojaVisita", e, c.nif, this.loadPageContent)} />}</td>
                                    <td style={{ textAlign: "center", width:'100px' }}>{c.documentos.prevencionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                      this.setState({ dialogCentroTrabajo: { opened: true, title: "¿Desea verificar el Plan de Prevención de Riesgos de " + c.nombre + "?", docName: "prevencionRiesgos", finca: e, ct:c } })
                                      abrirArchivo(c.documentos.prevencionRiesgos?.ruta)
                                    }} /> :
                                      <CustomSwitch checked={c.documentos.prevencionRiesgos?.validado} onClick={c.documentos.prevencionRiesgos?.validado ? () => this.setState({ verDocumentacionCentroTrabajo: { opened: true, nombreDoc: 'prevencionRiesgos', ct: c, finca: e } }) : () => subirDocumentacionCentroTrabajo("prevencionRiesgos", e, c.nif, this.loadPageContent)} />}</td>
                                    <td style={{ textAlign: "center", width:'100px' }}>{c.documentos.evaluacionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                      this.setState({ dialogCentroTrabajo: { opened: true, title: "¿Desea verificar la Identificación de Riesgos de " + c.razonSocial + "?", docName: "evaluacionRiesgos", finca: e, ct:c } })
                                      abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)
                                    }} /> :
                                    this.state.centrosTrabajo.includes(c.nif) ? 
                                    <>
                                      <CustomSwitch checked={c.documentos.evaluacionRiesgos?.validado} onClick={() => abrirPdfCt(e.nif, c.nif, c.documentos.evaluacionRiesgos?.ruta)} />
                                    </>
                                    : 
                                      <CustomSwitch checked={c.documentos.evaluacionRiesgos?.validado} onClick={c.documentos.evaluacionRiesgos?.validado ? () => this.setState({ verDocumentacionCentroTrabajo: { opened: true, nombreDoc: 'evaluacionRiesgos', ct: c, finca: e } }) : () => subirDocumentacionCentroTrabajo("evaluacionRiesgos", e, c.nif, this.loadPageContent)} />}</td>
                                    <td style={{ textAlign: "center", width:'100px' }}>{c.documentos.planEmergencia?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                                      this.setState({ dialogCentroTrabajo: { opened: true, title: "¿Desea verificar el Plan de Emergencia de " + c.razonSocial + "?", docName: "planEmergencia", finca: e, ct:c } })
                                      abrirArchivo(c.documentos.planEmergencia?.ruta)
                                    }} /> :
                                    this.state.centrosTrabajo.includes(c.nif) ? 
                                      <CustomSwitch checked={c.documentos.planEmergencia?.validado} onClick={() => abrirPdfCt(e.nif, c.nif, c.documentos.planEmergencia?.ruta)} />
                                    : 
                                      <CustomSwitch checked={c.documentos.planEmergencia?.validado} onClick={c.documentos.planEmergencia?.validado ? () => this.setState({ verDocumentacionCentroTrabajo: { opened: true, nombreDoc: 'planEmergencia', ct: c, finca: e } }) : () => subirDocumentacionCentroTrabajo("planEmergencia", e, c.nif, this.loadPageContent)} />}</td>
                                    
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    {/* <td style={{ cursor:"pointer", textAlign: "center" }}>{c.renovada ? <img src={Tick} width="30px" alt="todas renovadas" /> : null}</td> */}
                                    <td style={{ cursor:"pointer", textAlign: "center" }}></td>
                                    <td style={{ textAlign: "center", width: '50px'}}><img src={Delete} alt="ELIMINAR" className="tableIcon" onClick={() => this.setState({ eliminarCentroTrabajo: { opened: true, ct: c, finca: e } })}></img></td>
                                    <td style={{ textAlign: "center", width: 105 }}><NoVisitado finca={e} nif={c.nif} tipo={'centroTrabajo'} /></td>
                                  </tr>
                                )
                              })}
                            </>
                          }
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <input type="file" id="fileElem" hidden></input>
            </div>
          </div>
        </div>
        {/* VALIDAR DOCUMENTACIÓN */}
        <Dialog
          open={this.state.dialog.opened}
          onClose={() => this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El archivo se está descargando...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.noValidarDocumentacion()} color="inherit" style={{ color: "#FF5252" }}>
              No Validar
            </Button>
            <Button onClick={() => this.validarDocumentacion()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Validar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* VALIDAR DOCUMENTACIÓN CENTRO TRABAJO */}
        <Dialog
          open={this.state.dialogCentroTrabajo.opened}
          onClose={() => this.setState({ dialogCentroTrabajo: { opened: false, title: "", docName: "", proveedor: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.dialogCentroTrabajo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El archivo se está descargando...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.noValidarDocumentacionCentroTrabajo()} color="inherit" style={{ color: "#FF5252" }}>
              No Validar
            </Button>
            <Button onClick={() => this.validarDocumentacionCentroTrabajo()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Validar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* CAMBIAR AAFF */}
        <Dialog
          open={this.state.cambiarAAFF.opened}
          onClose={() => this.setState({ cambiarAAFF: { opened: false, finca: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres cambiar el aaff de <b>{this.state.cambiarAAFF.finca?.razonSocial}</b>?</DialogTitle>
          <form noValidate autoComplete="off">
            <div style={{margin: "8px 30px"}}>
              <TextField id="buscarAAFF" name="buscarAAFF" label="Buscar AAFF por nif"  onChange={this.mostraraaff} style={{margin: "0 0 40px 0"}} />
              <Select defaultValue={this.state.aaff.nif} style={{ width:"100%" }}
                native
                name="aaff"
                id="aaff"
                onChange={this.seleccionaraaff}
              >
                  
                  {this.state.aaffBuscado===null ?
                    this.state.aaffs.map((j, i) => {
                      return(
                        <option value={j.nif} data-name={j.razonSocial}>{j.razonSocial}</option>
                      )
                    })
                  :
                    this.state.nuevaLista.map((j, i) => {
                      return(
                        <option value={j.nif} data-name={j.razonSocial}>{j.razonSocial}</option>
                      )
                    })
                  }
              </Select>
            </div>
            <DialogActions>
              <Button onClick={() => this.setState({ cambiarAAFF: { opened: false, finca: undefined, aaff:undefined } })} color="inherit" style={{ color: "#FF5252" }}>
                Cancelar
              </Button>
              <Button onClick={this.cambiaraaff} color="inherit" style={{ color: "#7bc977" }} autoFocus>
                <b>Cambiar</b>
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        {/* ELIMINAR AAFF */}
        <Dialog
          open={this.state.eliminarAAFF.opened}
          onClose={() => this.setState({ eliminarAAFF: { opened: false, aaff: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quiéres eliminar a <b>{this.state.eliminarAAFF.aaff?.razonSocial}</b> y todas sus fincas y proveedores?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarAAFF: { opened: false, aaff: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarAAFF} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* ELIMINAR UNA FINCA */}
        <Dialog
          open={this.state.eliminarFinca.opened}
          onClose={() => this.setState({ eliminarFinca: { opened: false, finca: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres eliminar la finca <b>{this.state.eliminarFinca.finca?.razonSocial}</b>?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarFinca: { opened: false, finca: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarFinca} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* ELIMINAR UN CENTRO DE TRABAJO */}
        <Dialog
          open={this.state.eliminarCentroTrabajo.opened}
          onClose={() => this.setState({ eliminarCentroTrabajo: { opened: false, ct: undefined, finca: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres eliminar el centro de trabajo <b>{this.state.eliminarCentroTrabajo.ct?.nombre}</b>?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarCentroTrabajo: { opened: false, ct: undefined, finca: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarCentroTrabajo} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* ELIMINAR TODAS LAS FINCAS */}
        <Dialog
          open={this.state.eliminarFincas}
          onClose={() => this.setState({ eliminarFincas: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres eliminar todas las fincas?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarFincas: false })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarFincas} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
        {/* VER DOCUMENTACIÓN */}
        <Dialog
          open={this.state.verDocumentacion.opened}
          onClose={() => this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, finca: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subir un nuevo documento implica sobreescribir el anterior
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              firestore.doc(`fincas/${this.state.verDocumentacion.finca.nif}`).set({
                documentos: {
                  [this.state.verDocumentacion.nombreDoc]: {
                    ruta: "",
                    validado: false,
                    pendiente: false
                  }
                }
              }, { merge: true }).then(() => {
                toast.success("Documento borrado con éxito")
                this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, finca: undefined } })
                this.loadPageContent()
              })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Borrar documento
            </Button>
            <Button onClick={() => {
              subirDocumentacionComunidad(this.state.verDocumentacion.nombreDoc, this.state.verDocumentacion.finca, this.loadPageContent)
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, finca: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Subir nuevo
            </Button>
            
            <Button onClick={() => {
              let ruta
              let ano = this.state.verDocumentacion.finca.documentos['hojaVisita'].ano
              /* let url = this.state.verDocumentacion.finca.documentos['hojaVisita'].ruta.split("/")
              let archivo = url[1].split(".")
              this.state.verDocumentacion.nombreDoc === 'hojaVisita' ?
                ruta = ano === 2022 || ano === undefined ? 
                  url[0]+"/"+archivo[0]+"."+archivo[1]
                : 
                  url[0]+"/"+archivo[0]+"_"+ano+"."+archivo[1]
              : */
                ruta = this.state.verDocumentacion.finca.documentos[this.state.verDocumentacion.nombreDoc].ruta
                console.log(ruta)
                console.log(this.state.verDocumentacion)
              abrirArchivo(ruta)
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, finca: undefined } })
            }} color="inherit" /* style={this.state.verDocumentacion.nombreDoc === 'planEmergencia' ? {display:'none'} : {display:'block', color: "#fc7271"}} */ style={{ color: "#fc7271" }} autoFocus>
              <b>VER</b>
            </Button>
            {/* <Button onClick={() => {
              console.log(this.state.verDocumentacion.finca.nif)
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined, finca: undefined } })
            }} style={this.state.verDocumentacion.nombreDoc !== 'planEmergencia' ? {display:'none'} : {display:'block', color: "#fc7271"}}>
              <Link to={"/comunidades/"+ this.state.verDocumentacion.finca?.nif +"/planEmergencia"} target="_blank"><b>VER</b></Link>
            </Button> */}
          </DialogActions>
        </Dialog>
        {/* VER DOCUMENTACIÓN CENTRO TRABAJO */}
        <Dialog
          open={this.state.verDocumentacionCentroTrabajo.opened}
          onClose={() => this.setState({ verDocumentacionCentroTrabajo: { opened: false, nombreDoc: undefined, ct: undefined, finca: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subir un nuevo documento implica sobreescribir el anterior
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              const docAct = {...this.state.verDocumentacionCentroTrabajo.ct.documentos, [this.state.verDocumentacionCentroTrabajo.nombreDoc]: {validado: false, pendiente: false, ruta: ''}}
              let toRemove = []
              toRemove.push(this.state.verDocumentacionCentroTrabajo.ct)
              firestore.collection('fincas').doc(this.state.verDocumentacionCentroTrabajo.finca.nif).set({
                centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
              }, { merge: true })
              firestore.doc(`fincas/${this.state.verDocumentacionCentroTrabajo.finca.nif}`).set({
                centrosTrabajo:
                firebase.firestore.FieldValue.arrayUnion({
                  nombre: this.state.verDocumentacionCentroTrabajo.ct.nombre,
                  nif: this.state.verDocumentacionCentroTrabajo.ct.nif,
                  documentos: docAct
                })
              }, { merge: true }).then(() => {
                toast.success("Documento borrado con éxito")
                this.setState({ verDocumentacionCentroTrabajo: { opened: false, nombreDoc: undefined, ct:undefined, finca: undefined } })
                this.loadPageContent()
              })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Borrar documento
            </Button>
            <Button onClick={() => {
              subirDocumentacionCentroTrabajo(this.state.verDocumentacionCentroTrabajo.nombreDoc, this.state.verDocumentacionCentroTrabajo.finca, this.state.verDocumentacionCentroTrabajo.ct.nif, this.loadPageContent)
              this.setState({ verDocumentacionCentroTrabajo: { opened: false, nombreDoc: undefined, ct:undefined, finca: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }}>
              Subir nuevo
            </Button>
            <Button onClick={() => {
              abrirArchivo(this.state.verDocumentacionCentroTrabajo.ct.documentos[this.state.verDocumentacionCentroTrabajo.nombreDoc].ruta)
              this.setState({ verDocumentacionCentroTrabajo: { opened: false, nombreDoc: undefined, ct: undefined, finca: undefined } })
            }} color="inherit" style={{ color: "#fc7271" }} autoFocus>
              <b>VER</b>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withRouter(Comunidades);

